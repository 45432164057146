@import "../../global.scss";

.profile-page {
  max-width: 1400px;
  margin: 4.5rem auto;
  @include box-center($justify: center, $direction: column);
  .img-wrapper.profile {
    height: 10rem !important;
    width: 10rem !important;
    border-radius: 50%;
    overflow: hidden;
  }
  .user-info {
    @include box-center($justify: center);
    width: fit-content;
  }
  .filter-group li {
    flex: 1;
  }
  .filter-dugme {
    width: 100% !important;
    height: 3rem !important;
  }
  .user-tekst {
    width: 40%;
    padding-left: 4rem;
    .imePrezime {
      margin-bottom: 1rem;
      color: $zelena_5;
      font-size: 2.4rem;
      font-weight: 500;
    }
    .deskripcija {
      font-size: 1.2rem;
    }
  }
}
.lokacija,
.deskripcija {
  p {
    color: $zelena_5;
  }
}
.user-points {
  font-weight: bold;
  color: $zelena_4;
  padding-bottom: 10px;
}
.dropdown {
  position: relative;
  font-size: small;
}

.dropdown .dropdown-btn {
  margin-top: 0.5rem;
  padding: 15px 10px;
  background: #fff;
  border-radius: 1.5rem;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  color: $zelena_5;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.dropdown .dropdown-content {
  overflow-y: scroll;
  max-height: 300px;
  position: absolute;
  top: 110%;
  left: 0;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  color: #333;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 1.5rem;
}

.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all ease-in 0.2s;
}

.dropdown .dropdown-content .dropdown-item:hover {
  background: #f4f4f4;
}

.error-poruka {
  text-align: center;
  color: #ff105f;
}

.uspeh-poruka {
  text-align: center;
  color: #18ff10;
}
.image-button {
  cursor: pointer;
  width: fit-content;
  label {
    cursor: pointer;
  }
}

.modal-objavi {
  width: 20rem;
  height: 25rem;
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $zelena_1;
  border-radius: 2rem;
  flex-direction: column;
  box-shadow: 1px 0px 4px 0px rgba(51, 51, 51, 0.219);
  .deskripcija-tekst {
    outline: none;
    resize: none;
    border: none;
    border-radius: 1rem;
    height: 6rem;
    width: 100%;
    padding: 0.2rem 0.4rem;
    margin-top: 1rem;
    font-family: "Jost", sans-serif;
  }
  .dugmad {
    margin-top: 1rem;
    .dugme {
      height: 2.2rem;
      width: fit-content;
      padding: 0.2rem 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 1rem;
      margin: 0 0.5rem;
      //   box-shadow: 0 10px 10px rgb(0 0 0 / 40%);
      background: $zelena_4;
      color: #fff;
      font-weight: 600;
      transition: all 0.7s;
      cursor: pointer;
      &.ponisti {
        background: #ff5b69;
        cursor: pointer;
      }
      &.dodaj {
        background: $zelena_5;
        display: flex;
        gap: 3px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .user-info {
    flex-direction: column !important;
    // @include box-center($justify: center, $direction: column);
  }

  .user-tekst {
    width: 80% !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
    .deskripcija {
      width: initial;
    }
  }
}
