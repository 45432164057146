@import "../../global.scss"
  
@keyframes rotate1
  to
    transform: rotate(360deg)

@keyframes rotate2
  to
    transform: rotate(-360deg)

$loading-color: #34495e

$loader-col1: $zelena_2
$loader-col2: $zelena_3
$loader-col3: $zelena_4
$white: #fff

=rotate-loader-mixin($border-color, $degrees)
  border-top: 10px solid $border-color
  transform: rotate($degrees)

.loader-wrapper
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center

.loading
  width: 12.5rem
  height: 12.5rem
  box-sizing: border-box
  border-radius: 50%
  border-top: 10px solid $loader-col1
  position: relative
  animation: rotate1 2s linear infinite
  &::before, &::after
    content: ''
    width: 12.5rem
    height: 12.5rem
    position: absolute
    left: 0
    top: -10px
    box-sizing: border-box
    border-radius: 50%
  &::before
    +rotate-loader-mixin($loader-col2, 120deg)
  &::after
    +rotate-loader-mixin($loader-col3, 240deg)
  span
    position: absolute
    width: 12.5rem
    height: 12.5rem
    color: #205372
    text-align: center
    line-height: 12.5rem
    animation: rotate2 2s linear infinite
