.app-layout {
  width: 100%;
  background-color: var(--white);
}
.app-content {
  padding: 20px 15px 20px 15px;
  flex: 1;
  background: linear-gradient(156.1deg, #dbe7df 0%, #c6e0df 102.49%);
  height: max-content;
  min-height: 100vh;
}
