@import "../../global.scss";

.post {
  position: relative;
  width: 17rem;
  height: 22rem;
  border-radius: 1rem;
  overflow: hidden;
  margin: 1rem;
  .influenser {
    background: #ffdc5f;
    border-radius: 1.5rem;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;
    .imePrezime {
      color: $zelena_5 !important;
      padding-left: 0.5rem;
    }
  }
  .dogadjaj-info {
    position: absolute;
    top: 3.5rem;
    left: 50%;
    transform: translateX(-38%);
    background: $zelena_1;
    border-radius: 1rem;
    padding: 1rem;
    width: 70%;
    &:before {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      background: $zelena_1;
      position: absolute;
      top: -0.5rem;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
    p {
      margin-bottom: 0.1rem;
      color: $zelena_5 !important;
    }
  }
  .img-wrapper {
    height: 1.6rem !important;
    width: 1.6rem !important;
  }
  img {
    @include fit-image;
    filter: brightness(0.6);
  }
  .komentari {
    background: #fff;
    width: 17rem;
    transform: translateX(-0.8rem);
    position: absolute;
    bottom: 0;
    height: 80%;
    padding: 0.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    .kom-line {
      width: 60%;
      height: 5px;
      border-radius: 0.1rem;
      background: $zelena_5;
      position: absolute;
      top: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    ul {
      height: 85%;
      margin-top: 0.5rem;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0rem;
      }
      li {
        width: 100%;
      }
    }
    .komentarisi-forma {
      position: relative;
      .submit {
        cursor: pointer;
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        outline: none;
        border: none;
        border-radius: 50%;
        background: $zelena_0;
        width: 1.3rem;
        height: 1.3rem;
        @include box-center($justify: center);
      }
    }
    .komentarisi {
      outline: none;
      border: none;
      border-radius: 1rem;
      background: $zelena_1;
      width: 100%;
      height: 2rem;
      padding: 0 0.5rem;
    }
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.4rem 0.8rem;
    width: 100%;
    height: 100%;
    z-index: 2;
    .top-info {
      width: 100%;
      @include box-center($justify: space-between);
      p {
        color: #fff;
        font-size: 0.8rem;
      }
      .lokacija {
        display: flex;
        align-items: center;
      }
    }
    .bottom-info {
      position: absolute;
      bottom: 0rem;
      width: 100%;
      transform: translateX(-0.8rem);
      padding: 0.4rem 0.8rem;
      p {
        color: #fff;
      }
    }
    .opis-posta {
      font-size: 0.7rem;
      padding: 0.5rem 0;
    }
    .user {
      width: fit-content;
      @include box-center($justify: space-between);
      p {
        padding-left: 0.5rem;
      }
    }
    .funkcionalnosti {
      bottom: 0;
      height: 2rem;
      width: 100%;

      @include box-center($justify: space-around);
    }
    .lajk,
    .komentar,
    .ucestvujem {
      cursor: pointer;
      span {
        color: #fff;
        margin-left: 0.2rem;
      }
      @include box-center($justify: space-around);
    }
  }
}

.bottom-gradient {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 4rem;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(32, 83, 114, 0.8) 100%
  );
}

.top-gradient {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 4rem;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(52, 158, 158, 0.6) 100%
  );
}

@media screen and (min-width: 700px) {
  .post {
    position: relative;
    width: 16rem;
    height: 20rem;
    .komentari {
      width: 16rem;
    }
  }
}
