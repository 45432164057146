@import "../../global.scss";

.landing-page {
  min-width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.landing-img-wrapper {
  margin-bottom: 100px;
  padding-top: 50px;
}
.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .button-link {
    width: 100%;
    button {
      width: 100%;
      min-width: 200px;
      min-height: 60px;
      border-radius: 40px;
      border: none;
      cursor: pointer;
      color: white;
      font-weight: bold;
      font-size: 20px;
    }
    #login-button {
      background: linear-gradient(90deg, #54c693 0%, #205372 100%);
    }
    #register-button {
      background-color: $zelena_0;
      color: $zelena_4;
      border: 3px $zelena_4 solid;
    }
  }
}
