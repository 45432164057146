@import "../../global.scss";

.main-nav {
  width: 15rem;
  background-color: $zelena_1;
  border-radius: 2rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 1rem;
  z-index: 5;
}

.img-wrapper.logo {
  height: 2.4rem;
}

.profile {
  height: 40px;
}

.main-nav ul {
  @include box-center($justify: space-around);
  padding: 0.6rem 0;
  a {
    padding: 0.6rem 0;
    width: 1.8rem;
    height: 1.8rem;
    @include box-center($justify: space-around);
    cursor: pointer;
  }
}

a.active {
  background: #54c693;
  border-radius: 50%;
}

@media screen and (max-width: 700px) {
  .main-nav {
    bottom: 1rem;
    top: initial;
  }
}
