@import "../../global.scss";

.post-list {
  max-width: 1400px;
  margin: 2rem auto;
  ul {
    @include box-center($justify: center);
    width: 100%;
    flex-wrap: wrap;
  }
}
