@import "../../global.scss";

.top-bar {
  width: 100%;
  padding: 1rem 2rem;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  @include box-center($justify: space-between);
}

.profile {
  background: $zelena_4;
  border-radius: 1.5rem;
  padding: 0.2rem;
  @include box-center($justify: space-between);
  p {
    padding: 0 1rem;
    color: $zelena_0;
  }
}

.img-wrapper {
  height: 1.6rem;
  img {
    @include fit-image;
  }
  &.user {
    border-radius: 50%;
    overflow: hidden;
    background: $zelena_0;
  }
}
