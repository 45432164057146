@import "../../global.scss";

.komentar-glavni {
  margin-top: 0.7rem;
  .komentator {
    @include box-center($justify: space-between);
    width: fit-content;
    p {
      padding-left: 0.5rem;
      color: $zelena_5;
      font-weight: 500;
    }
  }
  .komentar-tekst {
    margin-top: 0.5rem;
    color: $zelena_5;
    font-size: 0.7rem;
  }
}
