@import "../../global.scss";

.modal-influenser {
  width: 90%;
  height: 85%;
  padding: 1rem;
  background: $zelena_1;
  border-radius: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .influenser-info-dogadjaj {
    outline: none;
    resize: none;
    border: none;
    border-radius: 1rem;
    height: 6rem;
    width: 100%;
    padding: 0.2rem 0.4rem;
    margin-top: 1rem;
    font-family: "Jost", sans-serif;
  }
  input {
    width: 100%;
    padding: 10px 5px;
    margin: 5px 0;
    border: 0;
    border-bottom: 1px solid #999;
    outline: none;
    background: transparent;
  }
  .dugmad {
    margin-top: 1rem;
    width: 110%;
    .dugme {
      height: 2.2rem;
      width: fit-content;
      padding: 0.2rem 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 1rem;
      margin: 0 0.5rem;
      //   box-shadow: 0 10px 10px rgb(0 0 0 / 40%);
      background: $zelena_4;
      color: #fff;
      font-weight: 600;
      transition: all 0.7s;
      cursor: pointer;
      &.ponisti {
        background: #ff5b69;
        cursor: pointer;
      }
      &.dodaj {
        background: $zelena_5;
        display: flex;
        gap: 3px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
