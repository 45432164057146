@import "../../global.scss";

.filter-group {
  width: fit-content;
  margin-top: 1.5rem;
  ul {
    @include box-center($justify: space-between);
    li {
      margin-right: 0.5rem;
    }
    button {
      height: 2.2rem;
      width: fit-content;
      padding: 0.2rem 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 1rem;
      margin: 0 0.5rem;
      //   box-shadow: 0 10px 10px rgb(0 0 0 / 40%);
      background: $zelena_5;
      color: #fff;
      font-weight: 600;
      transition: all 0.7s;
      cursor: pointer;
    }
  }
}
