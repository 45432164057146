@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Jost:wght@200;300;400;500&family=Roboto&display=swap");

body {
  margin: 0;
  margin: auto;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156.1deg, #dbe7df 0%, #c6e0df 102.49%);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}
