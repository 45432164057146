//boje
$zelena_0: #f0fff2;
$zelena_1: #ccf4cf;
$zelena_2: #7ae593;
$zelena_3: #54c693;
$zelena_4: #349e9e;
$zelena_5: #205372;

//mixins
@mixin box-center($direction: row, $justify: center) {
  display: flex;
  align-items: center;
  justify-content: $justify;
  flex-direction: $direction;
}

@mixin fit-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

@mixin contain-image {
  object-fit: contain;
  object-position: center;
  width: 100%;
}
